<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout></headerLayout>
		</section>
    <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>고객 사례</h2>
                    <!-- <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p> -->
                </div>
                <div class="location">
                    <ul>
                        <li><a>PRODUCTS</a></li>
                        <li><a>VPN</a></li>
                        <li><span>고객사례</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="sub-section vpnCTCas">
            <p class="cas-tit">초연결 시대 새로운 교육 지평을 여는 스마트 인프라 구축 – 서울특별시 교육청</p>
            <div class="innWrap flex">
                <div class="mgr50"><img src="@/assets/images/vpnCTCas.png" alt="서울특별시 교육청 로고" ></div>
                <div>
                    <table class="tbl_type01 tbl_dot">
                        <colgroup>
                            <col style="width: 200px;">
                            <col style="">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th class="agc">업종</th>
                                <td>
                                    <div class="dotList pdt0">
                                        <ul>
                                            <li>교육청(초/중/고 포함)</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="agc">규모</th>
                                <td>
                                    <div class="dotList pdt0">
                                        <ul>
                                            <li>42,092 명(초/중/고 재학생 수 기준)</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="agc">제공 서비스</th>
                                <td>
                                    <div class="dotList pdt0">
                                        <ul>
                                            <li>KT 스쿨넷 서비스</li>
                                            <li>KT LTE 전용 서비스</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="agc">과제</th>
                                <td>
                                    <div class="dotList pdt0">
                                        <ul>
                                            <li>권역별/지역별 전용 통신환경 구성</li>
                                            <li>통합보안관제 강화</li>
                                            <li>정보보안 시스템 고도화</li>
                                            <li>기술지원 및 유지관리</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="agc">도입효과</th>
                                <td>
                                    <div class="dotList pdt0">
                                        <ul>
                                            <li>교육청 〈ㅡ〉 이용기관까지 교육청 전용설비 100% 제공</li>
                                            <li>빈틈없는 보안연동 프로세스 설계와 26종 다중 보안체계 적용</li>
                                            <li>개인정보 오남용/유출사고 예방을 위한 실시간 검출 및 차단 100% 완성</li>
                                            <li>스쿨넷 사이버 침해사고 발생 시 즉각적인 대응 조직 및 체계 상시 운영</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>


    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
    }
  },
  created: function () {

  },
  mounted: function () {
  },
  methods: {
    clickTp: function () {
    }
  }
}
</script>